import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";
import BlogHeading4 from "../components/blog/BlogHeading4";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`
  max-width: 750px;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) => {
    return <BlogPostWrapper>
        <StyledMainContent>
        <SEO
            title="Stock Your Pantry For The Ultimate Home Cook Experience"
            description="Read all about the essential items every pantry needs and how to stock your pantry so that you have all your cooking and baking bases covered."
            location={ location }
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Stock Your Pantry For The Ultimate Home Cook Experience: Guide & Checklists
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Stock-Your-Pantry-For-The-Ultimate-Home-Cook-Experience.jpg"
                             alt="Stock Your Pantry For The Ultimate Home Cook Experience: Guide & Checklist"/>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            The goal of this comprehensive blog post is to help you get a clear idea of what essential pantry items are,
            how to stock a pantry for the first time (for example, after you’ve just moved house), and how to stock a
            pantry for a whole year if you’re interested in doing so. </Paragraph>
        <Paragraph>
            Some of the reasons why it is great to have a well stocked pantry include:</Paragraph>
        <UnorderedList>
            <UnorderedListItem>Saving time by not going to the grocery store that often</UnorderedListItem>
            <UnorderedListItem>Saving money on bulk shopping</UnorderedListItem>
            <UnorderedListItem>Having all ingredients on hand for impromptu cooking or baking ideas</UnorderedListItem>
            <UnorderedListItem>Reducing waste by using up all the items before they expire instead of throwing them away
                after one use, and more</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Let’s first take a look at what are considered to be essential pantry items.
        </Paragraph>
        <BlogHeading2>
            Essential pantry items
        </BlogHeading2>
        <Paragraph>It would be irresponsible of us to claim that the following items are what <i><b>every
            single</b></i> kitchen pantry needs. The truth is, we’re just throwing out suggestions and helping you not
            overlook an important food category. Your pantry should always reflect the cooking and nutrition needs of
            your household, so approach our lists with a critical eye. Adopt only the pantry staples you know you’ll be
            using and that are in line with your lifestyle. </Paragraph>
        <Paragraph>
            That being said, for your consideration, here is a checklist of <InternalLink
            to="/essential-pantry-items/">essential pantry items to stock up on.</InternalLink>
        </Paragraph>
        <BlogHeading3>
            Baking supplies
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>All-purpose flour</UnorderedListItem>
            <UnorderedListItem>Baking chocolate</UnorderedListItem>
            <UnorderedListItem>Baking powder</UnorderedListItem>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Brown sugar</UnorderedListItem>
            <UnorderedListItem>Chocolate chips</UnorderedListItem>
            <UnorderedListItem>Cocoa powder</UnorderedListItem>
            <UnorderedListItem>Cornmeal</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Powdered sugar</UnorderedListItem>
            <UnorderedListItem>Pure vanilla extract</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Canned goods
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Canned beans: black beans, white beans, any type of beans you like</UnorderedListItem>
            <UnorderedListItem>Canned soup</UnorderedListItem>
            <UnorderedListItem>Canned tuna (in oil, brine, or spring water, whatever your preference may
                be)</UnorderedListItem>
            <UnorderedListItem>Chicken stock</UnorderedListItem>
            <UnorderedListItem>Diced tomatoes</UnorderedListItem>
            <UnorderedListItem>Tomato paste</UnorderedListItem>
            <UnorderedListItem>Tomato sauce</UnorderedListItem>
            <UnorderedListItem>Vegetable stock</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Condiments and sauces
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Basic vinaigrette</UnorderedListItem>
            <UnorderedListItem>Hot sauce</UnorderedListItem>
            <UnorderedListItem>Ketchup</UnorderedListItem>
            <UnorderedListItem>Mayonnaise</UnorderedListItem>
            <UnorderedListItem>Mustard (Dijon or yellow)</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Soy sauce and/or tamari sauce</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Frozen goods
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Bread, sliced thickly (for toast)</UnorderedListItem>
            <UnorderedListItem>Frozen fruit: mango, peaches, berries</UnorderedListItem>
            <UnorderedListItem>Frozen veggies: peas, corn, spinach, green beans</UnorderedListItem>
            <UnorderedListItem>Meat: chicken, sausages</UnorderedListItem>
            <UnorderedListItem>Seafood: thick fish fillets, shrimp</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Grains and starches
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Dried pasta (any shape or shapes you like)</UnorderedListItem>
            <UnorderedListItem>Grain crackers</UnorderedListItem>
            <UnorderedListItem>Plain bread crumbs</UnorderedListItem>
            <UnorderedListItem>White rice (or brown rice, if you prefer)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Nuts
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Peanut butter (both smooth and crunchy, or your personal preference)</UnorderedListItem>
            <UnorderedListItem>Roasted peanuts</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Preserves and pickled food
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Anchovies</UnorderedListItem>
            <UnorderedListItem>Fruit jams (apricot, strawberry, any other kind you like)</UnorderedListItem>
            <UnorderedListItem>Pickles</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Produce
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>All-purpose potatoes</UnorderedListItem>
            <UnorderedListItem>Garlic</UnorderedListItem>
            <UnorderedListItem>Lemons</UnorderedListItem>
            <UnorderedListItem>Onions</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Spices and dried herbs
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Bay leaves</UnorderedListItem>
            <UnorderedListItem>Black peppercorns</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Dried oregano</UnorderedListItem>
            <UnorderedListItem>Garlic powder</UnorderedListItem>
            <UnorderedListItem>Ground cayenne</UnorderedListItem>
            <UnorderedListItem>Ground cinnamon</UnorderedListItem>
            <UnorderedListItem>Red pepper flakes</UnorderedListItem>
            <UnorderedListItem>Salt</UnorderedListItem>
            <UnorderedListItem>Sweet paprika</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Sweeteners
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Granulated sugar</UnorderedListItem>
            <UnorderedListItem>Honey</UnorderedListItem>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Oils nad vinegars
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Extra virgin olive oil</UnorderedListItem>
            <UnorderedListItem>Neutral cooking oil (canola, sunflower seed, or grapeseed oil)</UnorderedListItem>
            <UnorderedListItem>Red wine vinegar</UnorderedListItem>
            <UnorderedListItem>White vinegar (or white wine vinegar)</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            Instead of writing all these items down on a piece of paper, use a simple shopping list app such
            as <InternalLink to="/#download-section">Going Shopping</InternalLink> to store everything in one place for
            future shopping trips.
        </BlockQuote>
        <BlogHeading2>
            Stocking a pantry for the first time
        </BlogHeading2>
        <Paragraph>
            So you’ve recently moved to a new place. You’re standing in your kitchen, looking at the empty cupboards or
            whatever pantry space you have and feeling a bit overwhelmed.
        </Paragraph>
        <Paragraph>
            It’s time to start over, to build your new pantry from the ground up. That seems like a lot of work.
        </Paragraph>
        <Paragraph>
            And it is, in a way. But it doesn't have to be a source of stress or dread. When you break everything down,
            it’s actually quite manageable to stock a whole pantry again.
        </Paragraph>
        <Paragraph>
            Here are four simple steps you need to take <InternalLink to="/how-to-stock-a-pantry-for-the-first-time/">to stock a pantry for the first time</InternalLink>.
        </Paragraph>
        <BlogHeading3>
            Step 1: Nothing without a budget
        </BlogHeading3>
        <Paragraph>
            In an ideal world, everyone would be able to go to any grocery store and splurge on anything they want
            whenever they want it.
        </Paragraph>
        <Paragraph>
            Of course, this world is far from ideal. Therefore, before you set out to stock up your new pantry, it would
            be great if you could figure out how much money you can set aside for that project.
        </Paragraph>
        <Paragraph>
            If you happen to have enough to buy everything you need at once (though you may still want to split that up
            into several shopping trips because of logistics), then great!
        </Paragraph>
        <Paragraph>
            But if you’re <InternalLink to="/how-to-save-money-grocery-shopping/"> shopping on a budget</InternalLink>, there are two things to consider:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>How much money you can spend right away, for your first shopping trip (if
                any)</UnorderedListItem>
            <UnorderedListItem>How much money you can set aside each week (or month) until you bring your pantry up to
                par</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            the long run, the actual amount of money doesn't matter, whether it is $5 or $50 per week. If you cannot set
            aside much, it will take you a bit longer until you have a well stocked pantry, but that’s nothing to worry
            about. With the right priorities set, you will make good use of your pantry staples right from the get-go.
        </Paragraph>
        <BlogHeading3>
            Step 2: A complete list
        </BlogHeading3>
        <Paragraph>
            The next step is to write a list of all the things you want to have in your new pantry, at least for the
            time being.
        </Paragraph>
        <Paragraph>
            Take into account the list of essentials we laid out above and let it serve as inspiration. Start from those
            pantry staples and write down anything you think you will need. Don’t hesitate to add your personal staples
            that you use on a daily basis but we may have left out. Again, it’s crucial that this pantry serves your
            household, not the other way around.
        </Paragraph>
        <Paragraph>
            If you want, you can categorize your items the way we did. But if you’re shopping incrementally (which we
            highly recommend), bear in mind that you will be selecting specific items from each of those categories to
            buy first.
        </Paragraph>
        <Paragraph>
            Which brings us to the third step.
        </Paragraph>
        <BlogHeading3>
            Step 3: Priorities
        </BlogHeading3>
        <Paragraph>
            If you are determined to get all your pantry food in one go and can afford it, then by all means, do not let
            us stop you.
        </Paragraph>
        <Paragraph>
            If, however, you feel like that would be too much for you for any reason, then it would be best to
            prioritize the items on your list.
        </Paragraph>
        <Paragraph>
            On your first visit to the grocery store, you should buy all the ingredients without which you absolutely
            cannot make even the most basic of meals. Here is our recommendation for which pantry items to get first:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Dried pasta (any shape or shapes you like)</UnorderedListItem>
            <UnorderedListItem>Flour</UnorderedListItem>
            <UnorderedListItem>Granulated sugar (or brown sugar, if you prefer)</UnorderedListItem>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Rice (brown rice or white rice, your preference)</UnorderedListItem>
            <UnorderedListItem>Salt and black pepper</UnorderedListItem>
            <UnorderedListItem>Your preferred cooking oil or non-stick cooking oil spray</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            You might also want to add:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Coffee</UnorderedListItem>
            <UnorderedListItem>Produce: onions and potatoes</UnorderedListItem>
            <UnorderedListItem>Tea</UnorderedListItem>
            <UnorderedListItem>Your favorite condiments and sauces (mustard, ketchup, soy sauce,
                etc.)</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            That should set you up nicely until you get an opportunity to add more to your pantry.
        </Paragraph>
        <Paragraph>
            Regarding the following <InternalLink to="/grocery-shopping-tips/"> shopping
            trips</InternalLink>: you may prioritize other items according to how frequently you use them or just get a
            couple of new items from each category every time you go shopping until there is nothing left on the list to
            buy. Whatever way works for you is the best way to gradually stock your pantry.
        </Paragraph>
        <BlogHeading3>
            Step 4: Organize and restock
        </BlogHeading3>
        <Paragraph>
            Stocking your pantry for the first time is an excellent moment to organize it just the way you want it to
            be. Trust us, it is a lot more frustrating to have to reorganize a pantry that is already filled to the brim
            with things you use and some things you may have forgotten about.
        </Paragraph>
        <Paragraph>
            So take advantage of this clean slate to arrange the new pantry items in a way that makes sense to you.
            Create ‘zones’ on the pantry shelves where you group certain items together.
        </Paragraph>
        <Paragraph>
            For example, sweeteners such as syrups and sugars could go together. Or you may opt to group sugar with
            baking essentials. All condiments can go together, and nut butters can sit next to each other. Cans can be
            on one side of the pantry, jars on the other.
        </Paragraph>
        <Paragraph>
            A logically organized pantry (or at least logically for you and your household) will be of great help when
            it’s time to restock.
        </Paragraph>
        <Paragraph>
            One major advice we have for restocking is to <InternalLink
            to="/how-to-organize-your-grocery-shopping-list/"> keep a running shopping
            list</InternalLink> in the house (or kitchen or phone). This is essential for not forgetting what you need
            to buy. Whenever you notice you’re running low on something in the pantry, you add it to the shopping list.
            Down to the last jar of pickles? Add it to the list. Not much more flour? Onto the list it goes. Don’t wait
            until you’re completely out of an item to restock it because you never know when you might need it in a
            pinch.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage
                src="../assets/images/blogs/post-2022-6-Bags of dried pasta, rice, and various nuts and seeds.png"
                alt="Bags of dried pasta, rice, and various nuts and seeds."/>
        </ImageWithCaption>
        <BlogHeading2>How to stock a pantry for a year</BlogHeading2>
        <Paragraph>
            You may never have considered this, but quite a few people regularly stock their pantry for months or even a
            whole year. They do it for a variety of reasons, such as fear of food shortage or war, but also because
            winters in their region might be particularly harsh or they don’t want the obligation of restocking their
            pantry too often.
        </Paragraph>
        <Paragraph>
            Nevertheless, if you are in a position to do so, why not consider stocking your pantry for a year?
        </Paragraph>
        <Paragraph>
            It’s a big task, we’re not going to lie. But when broken down into actionable chunks, it can be just as easy
            as stocking a pantry for the first time. In fact, the steps to both are incredibly similar.
        </Paragraph>
        <Paragraph>
            Here’s <InternalLink to="/how-to-stock-a-pantry-for-a-year/">how to stock a pantry for a
            year</InternalLink>:
        </Paragraph>
        <BlogHeading3>
            Step 1: Figure out the amount of food
        </BlogHeading3>
        <Paragraph>
            The first step in stocking your pantry for the first time is figuring out your budget, but in this case,
            it’s figuring out how much pantry food you will need for a year.
        </Paragraph>
        <Paragraph>
            How to do this: track your food usage for a whole month. You can do it for a week or two, but it’s a lot
            more accurate to do it for a month (or even two, if you can). Write down how much of your pantry ingredients
            you use up during that month. It won’t be easy or fun, but it is necessary work to successfully stock your
            pantry for a long time.
        </Paragraph>
        <Paragraph>
            To get an approximation for a year, multiply the one-month quantities by 12. (To be on the safe side, you
            might want to multiply by 13 and make it 13 months’ worth of food.)
        </Paragraph>
        <BlogHeading3>
            Step 2: Pinpoint foods that last long
        </BlogHeading3>
        <Paragraph>
            Even if you buy enough potatoes for a year, chances are that they won’t make it to the twelve month mark
            intact. Same is true for several other pantry staples as well.
        </Paragraph>
        <Paragraph>
            So for this undertaking to work, you need to not only write a list of all the items you need in your pantry,
            but you also have to know which of them are shelf stable so that you don’t find yourself throwing away an
            absurd amount of food after six months.
        </Paragraph>
        <Paragraph>
            We can also help you here. Here is a checklist of the most long-lived pantry items you can buy:
        </Paragraph>
        <BlogHeading4>
            Foods that last indefinitely
        </BlogHeading4>
        <UnorderedList>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Dried beans (though they do become harder with age, so cooking time has to
                increase)</UnorderedListItem>
            <UnorderedListItem>Energy bars</UnorderedListItem>
            <UnorderedListItem>Hard liquor (not wine or beer, but rather distilled spirits)</UnorderedListItem>
            <UnorderedListItem>Honey</UnorderedListItem>
            <UnorderedListItem>Pure vanilla extract</UnorderedListItem>
            <UnorderedListItem>Salt</UnorderedListItem>
            <UnorderedListItem>Sugar (any kind, brown sugar, granulated sugar, or powdered sugar)</UnorderedListItem>
            <UnorderedListItem>Syrup (such as maple syrup or corn syrup)</UnorderedListItem>
            <UnorderedListItem>Uncooked rice (any kind - white rice, brown rice, etc.)</UnorderedListItem>
            <UnorderedListItem>Vinegars (white distilled vinegar, apple cider vinegar, balsamic vinegar,
                etc.)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading4>
            Foods that last for decades
        </BlogHeading4>
        <UnorderedList>
            <UnorderedListItem>Dried pasta</UnorderedListItem>
            <UnorderedListItem>Frozen foods (as long as they are kept frozen)</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Sealed instant coffee</UnorderedListItem>
            <UnorderedListItem>Soy sauce</UnorderedListItem>
        </UnorderedList>
        <BlogHeading4>
            Foods that last for years
        </BlogHeading4>
        <UnorderedList>
            <UnorderedListItem>Certain hard cheeses, such as Parmesan or aged cheddar</UnorderedListItem>
            <UnorderedListItem>Dark chocolate</UnorderedListItem>
            <UnorderedListItem>Hardtack</UnorderedListItem>
            <UnorderedListItem>Low-acid canned goods (canned meat, canned tuna or other types of canned fish, canned
                corn, canned beans, etc.)</UnorderedListItem>
            <UnorderedListItem>Peanut butter (the kind that doesn't have to be refrigerated)</UnorderedListItem>
            <UnorderedListItem>Powdered milk</UnorderedListItem>
            <UnorderedListItem>Ramen noodles</UnorderedListItem>
            <UnorderedListItem>Spam (unopened)</UnorderedListItem>
            <UnorderedListItem>Uncooked quinoa</UnorderedListItem>
            <UnorderedListItem>Unpopped corn kernels (popcorn)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading4>
            Foods that last up to a year
        </BlogHeading4>
        <UnorderedList>
            <UnorderedListItem>Bouillon cubes</UnorderedListItem>
            <UnorderedListItem>Cake mix</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
            <UnorderedListItem>Dark, unsweetened cocoa</UnorderedListItem>
            <UnorderedListItem>Dehydrated meat</UnorderedListItem>
            <UnorderedListItem>Dried fruit</UnorderedListItem>
            <UnorderedListItem>Flour</UnorderedListItem>
            <UnorderedListItem>Hard candies, jellied candies, caramel</UnorderedListItem>
            <UnorderedListItem>High-acid canned goods (canned fruit, pickles, sauerkraut, canned tomato soup,
                etc.)</UnorderedListItem>
            <UnorderedListItem>Jarred pasta sauce</UnorderedListItem>
            <UnorderedListItem>Unopened olive oil</UnorderedListItem>
            <UnorderedListItem>Unopened spice containers</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Step 3: Prepare your space
        </BlogHeading3>
        <Paragraph>
            Before you actually go out and get everything off your list of items that will last a year or longer, you
            need to prepare the space where you will store all of this.
        </Paragraph>
        <Paragraph>
            Make sure that everything is clean, vacuumed, and dust and cobweb free. You will need shelves, cabinets, or
            even an actual room dedicated to your large pantry.
        </Paragraph>
        <Paragraph>
            Additionally, you may need glass jars and other airtight containers to transfer the more sensitive
            ingredients to. It is crucial to keep everything stored in a dry, cool place that isn’t directly exposed to
            sunlight. An airtight container does wonders in protecting your pantry essentials from moisture and
            humidity.
        </Paragraph>
        <Paragraph>
            Do keep in mind that some items, such as powdered milk and instant coffee, must remain in their original
            sealed packages until you’re ready to use them.
        </Paragraph>
        <BlogHeading3>
            Step 4: Going shopping
        </BlogHeading3>
        <Paragraph>
            Shopping for this much pantry food can be a challenge. These two things might help:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Splitting the shopping into several trips - It will take longer to accumulate everything,
                but it will be far less stressful than trying to get it all at once. Especially if you need to visit
                several stores to do so.</UnorderedListItem>
            <UnorderedListItem>Not going alone - Take one or two adult (or near-adult) people with you. They can help
                with pushing carts, collecting things off the shelves, and putting them away in the car. Even if you
                split your grocery shopping, you can still benefit from extra manpower.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Step 5: Rotate your items
        </BlogHeading3>
        <Paragraph>
            When you’re finally looking at your fully stocked pantry that will last you at least several months, you
            need to remember that rotating these items is key.
        </Paragraph>
        <Paragraph>
            You want to place the items that will be expiring quicker at the front. The ones with a longer expiration
            date can hang out in the back. That way you won’t accidentally use up something that would’ve been good for
            longer while something else that will spoil soon sits on your pantry shelf untouched.
        </Paragraph>
        <Paragraph>
            Whenever you remove an item, bring the next one in line a little forward, so that you know which one is next
            to use according to its expiration date.
        </Paragraph>
        <BlogHeading2>
            Conclusion: Pantries are easy
        </BlogHeading2>
        <Paragraph>
            As you can see from this deep dive into how to build your pantry, it’s not actually that complicated to set
            things in motion. All you need is a little (or a lot, if you’re stocking up for a whole season or year)
            planning beforehand.
        </Paragraph>
        <Paragraph>We hope that our checklists helped you come up with your own pantry list ideas. With a solid shopping
            list, you can easily get everything you need either all at once or over the course of several grocery
            runs.</Paragraph>
        <Paragraph>
            A shopping list app, such as <InternalLink to="/#download-section"> Going Shopping</InternalLink>, will be
            of immense help in this department. With our free app you can even tag and color code items for easy
            recognition and reorder them however you want! Just what your pantry restocking efforts need.
        </Paragraph>

    </StyledMainContent>
    </BlogPostWrapper>
}


export default BlogPost;